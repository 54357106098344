import { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, DatePicker, Button, Row, Col } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { ClockCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import {
  marcarArriboSigsa,
  startSetStep,
  startUpdateFecha,
} from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";

import {
  hdiTipoFechas,
  mercaArriboHdi,
  putNotification,
} from "../../../context/actions/hdiActions";
import { Desasignar } from "./_Desasignar";
import { set } from "date-fns";

export const Arribo = ({
  asistencia: { estatusID, fechaArribo, esGnpApi, folioGnp },
}) => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [fecha, setFecha] = useState("");
  const { estatusFinalID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  const handleClick = () => {
    const body = {
      tipoFecha: hdiTipoFechas.fechaArribo,
      fechaArribo: fecha,
    };

    const bodyHdi = {
      tipoFecha: hdiTipoFechas.fechaArribo,
      fecha: new Date(fecha).toISOString(),
    };

    dispatch(marcarArriboSigsa(body));
    dispatch(mercaArriboHdi(bodyHdi));
    setShowModal2(false);
  };

  const handleModalOK = () => {
    const body = {
      fecha,
      asistenciaID,
      tipo: 1, // arribo
    };
    dispatch(startUpdateFecha(body));
    setShowModal(false);
  };

  const onDateChange = (momentTime, stringTime) => setFecha(stringTime);

  ////////////////////////////
  if (estatusID === 3 && estatusFinalID === 1)
    return (
      <>
        <div onClick={() => setShowModal2(true)} className="marcarStep">
          <PauseCircleOutlined /> Marcar arribo
        </div>
        <hr />
        <Desasignar />
        <Modal
          title="Establecer Fecha de Arribo"
          open={showModal2}
          footer={[
            <Button
              key={1}
              onClick={() => setShowModal2(false)}
              size="small"
              danger
            >
              cerrar
            </Button>,
          ]}
          onCancel={() => setShowModal2(false)}
        >
          <Row>
            <Col span={12}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Fecha y Hora"
                showTime
                onChange={onDateChange}
              />
            </Col>
            <Col span={12}>
              <Button
                disabled={!fecha}
                style={{ width: "100%" }}
                onClick={handleClick}
                type="primary"
              >
                Establecer Fecha
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );

  ////////////////////////////
  const date = dateFromString(fechaArribo);

  if (estatusID > 3)
    return (
      <div onDoubleClick={() => setShowModal(true)}>
        <ClockCircleOutlined /> {date.dateStep}
        <Modal
          title="Cambiar Fecha Arribo"
          open={showModal}
          onOk={handleModalOK}
          onCancel={() => setShowModal(false)}
        >
          <DatePicker showTime onChange={onDateChange} />
        </Modal>
      </div>
    );

  ////////////////////////////
  return <div></div>;
};
