import { Button, Modal, Select, Spin } from "antd";
import { useData } from "./useData";
import { TituloModal } from "../../../../components/tituloModal/TituloModal";

export const AsignacionApi = () => {
  const {
    open,
    setOpen,
    submit,
    opciones,
    disabled,
    setProveedorID,
    proveedorID,
    enviandoProveedorApi,
  } = useData();

  return (
    <div style={{ width: "100%" }}>
      {opciones.length >= 0 && (
        <Button
          onClick={() => setOpen(true)}
          type={"text"}
          className={"botonCardLeft2"}
          danger
        >
          Asignación directa por API
        </Button>
      )}

      <Modal
        title={<TituloModal titulo="Asignar Directo por API" />}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div style={{ marginBottom: 10, marginTop: 20 }}>
          <Select
            style={{ width: "100%" }}
            value={proveedorID}
            onChange={(val) => setProveedorID(val)}
            options={opciones}
          />
        </div>
        <div style={{ marginBottom: 50 }}>
          {enviandoProveedorApi ? (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Spin />
            </div>
          ) : (
            <Button
              style={{ width: "100%" }}
              disabled={disabled}
              type="primary"
              onClick={submit}
            >
              Asignar a Proveedor
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};
