import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  startGetProveedoresApi,
  startPostProveedorApi,
} from "../../../../context/actions/asistenciasActions";

export const useData = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { asistenciaID } = useParams();
  const [proveedorID, setProveedorID] = useState(null);
  const [opciones, setOpciones] = useState([]);
  const { proveedoresApi, enviandoProveedorApi } = useSelector(
    (state) => state.asistenciasReducer
  );

  useEffect(() => {
    dispatch(startGetProveedoresApi(asistenciaID));
  }, [asistenciaID, dispatch]);

  useEffect(() => {
    const list = proveedoresApi.map((item) => ({
      label: `(${item.conteo})  ${item.nombreComercial}`,
      value: item.proveedorID,
    }));

    setOpciones(list);
  }, [proveedoresApi]);

  const disabled = proveedorID === null;

  //
  const submit = async () => {
    const body = {
      asistenciaID,
      proveedorID,
    };

    console.log(body);

    dispatch(startPostProveedorApi(body));
    setOpen(false);
  };

  return {
    open,
    setOpen,
    submit,
    opciones,
    setProveedorID,
    proveedorID,
    disabled,
    enviandoProveedorApi,
  };
};
