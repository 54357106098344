import pjson from "../../package.json";
//export const URL_BASE = process.env.URL_BASE;


export const ver = pjson.version;
export const nameProject = pjson.name;

export const { ambiente } = window["runConfig"];
 export const { URL_BASE } = window["runConfig"];



export const { URL_HDI } = window["runConfig"];
export const { URL_QUALITAS } = window["runConfig"];
export const { URL_TRACKING } = window["runConfig"];

export const URL_BASE_POLIZARIO = "https://polizario2.back.escotel.mx/api";
export const URL_BASE_POLIZARIO_QUALITAS = URL_QUALITAS;


export const elAguilaID = "ve7SE85lpL";

export const URL_BASE_VER = `${URL_BASE}/api/Ver`;
export const URL_BASE_SMS = `${URL_BASE}/api/SMS`;

export const URL_ACTIONS_GNP = `${URL_BASE}/api/GnpActions`;

export const URL_LOGIN = `${URL_BASE}/api/auth/login`;

export const URL_BASE_CLIENTES = `${URL_BASE}/api/clientes`;
export const URL_BASE_ASISTENCIAS = `${URL_BASE}/api/asistencias`;

export const URL_BASE_ALLOWEDBROWSER = `${URL_BASE}/api/AllowedBrowser`;

export const URL_BASE_PROVEEDORES = `${URL_BASE}/api/proveedores`;

export const URL_BASE_HDI = URL_HDI;

export const URL_PROVEEDORES_ACTIVOS = `${URL_BASE}/api/cabina/GetProveedoresActivos`;

export const QUALITAS_URL = "https://siica.qualitas.com.mx/AAQ-WS/webservices";

export const URL_BASE_EscalamientoElAguila = `${URL_BASE}/api/EscalamientoElAguila`;

export const URL_ELAGUILA_ACTIONS = `${URL_BASE}/api/ElAguilaActions`;

export const URL_CABINA_ASIGNACION = `${URL_BASE}/api/CabinaAsignacion`;
export const URL_ASISTENCIA_INCIDENCIA = `${URL_BASE}/api/AsistenciaIncidencia`;
export const URL_ACTIVIDADES_SEGUIMIENTO = `${URL_BASE}/api/ActividadesSeguimiento`;
export const URL_ASISTENCIA_DESTINO= `${URL_BASE}/api/AsistenciaDestino`;

export const URL_SERVICIOS_PROPUESTOS = `${URL_BASE}/api/ServiciosPropuestos`;
export const URL_PROVEEDORES_ACTIVOS2 = `${URL_BASE}/api/ProveedoresActivos`;

