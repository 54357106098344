import { Col, Form, Input, notification, Row, Select, TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { URL_BASE } from '../../../constants/url';
import { useDispatch, useSelector } from 'react-redux';
import { post } from '../../../utils/fetch';
import { hdiClient } from '../../../utils/hdiApiClient';
import { useHistory } from 'react-router-dom';
import { DayjsConfig } from '../../../utils/configDayjs';
import { getFieldsHdiToCapture } from '../../../context/actions/hdiActions';
import { keyPairToFields } from '../../../utils/keyPairToFields';
import { customObjet } from "./customObject";

const format = DayjsConfig('YYYY-MM-DD')


export const useSelectCustom = () => {
    const {
      datosVehiculo_Color,
      datosVehiculo_Marca,
      datosVehiculo_Modelo,
      datosVehiculo_Placas,
      datosVehiculo_TipoVehiculo
    } = useSelector(state => state.hdiReducer.detailsService);

    const [treeData, setTreeData] = useState({})
    const [form] = Form.useForm()
    const history = useHistory()
    const dispatch = useDispatch()
    const details = useSelector(state => state.hdiReducer.detailsService)
    const fields = useSelector(state => state.hdiReducer.fieldsToCapture)
    const [optionsByAssistance, setOptionsByAssistance] = useState([])


    const getCampaniasHdi = async () => {
      const {data} = await axios.get(`${URL_BASE}/api/HdiActions/getcampanias`)
      const array = Object.groupBy(data, ({campania}) => campania)
      const finalServices = customObjet(data)
      const tree = Object.keys(array).map(item => ({
        value: array[item][0].campaniaID,
        selectable: false,
        title: item,
        children: Object.keys(Object.groupBy(array[item], ({tipoAsistencia}) => tipoAsistencia)).map((x, i) => ({
          title: `${x}`,
          value: `${array[item][0].campaniaID}-${array[item][i].servicioID}`,
        }))
      }))

      setTreeData({tree, finalServices})
    }


    function onChangeTypeService(value) {
     
      const [campaniaID, tipoAsistenciaId] = value.split('-')
      form.setFieldsValue({servicioID: undefined})
      setOptionsByAssistance(treeData?.finalServices[campaniaID][tipoAsistenciaId]?.map((serve
      ) => ({label: serve.servicio, value: serve.servicioID})) ?? [])

    }

    function onSelectServiceId(value) {
      
      void dispatch(getFieldsHdiToCapture(value)).then(() => {
        form.setFieldsValue({
          color: datosVehiculo_Color,
          placas: datosVehiculo_Placas,
          modelo: datosVehiculo_Modelo,
          'Marca del Vehiculo': datosVehiculo_Marca,
          'Tipo de vehículo': datosVehiculo_TipoVehiculo,
        })
      })
    }


    
    const submit = (geoDatos) => {
      form.validateFields().then(
        async (values) => {

          if (!geoDatos.latOrigen) {
            notification.error({message: 'Error', description: 'Favor de seleccionar la ubicación del servicio'})
            return
          }
          const {tipoAsistencia, servicioID, ...rest} = values
          const body = {
            ...values,
            ...keyPairToFields(rest),
            ...geoDatos,
            ...details,
            campaniaID: parseInt(values.tipoAsistencia.split('-')[0]),
            tipoAsistencia: parseInt(values.tipoAsistencia.split('-')[1]),
            datosPoliza_FechaInicioVigencia: format(details.datosPoliza_FechaInicioVigencia),
            datosPoliza_FechaFinVigencia: format(details.datosPoliza_FechaFinVigencia),
          }

          // const bodyToHdi = createHDIBodyFromApi(values, details, geoDatos, state.dateOnClick, 'hdi_12')
          // console.log({bodyToHdi})
          // return
          const result = await post(`${URL_BASE}/api/HdiActions`, body)


      
          if (result.code === 0) {
            await hdiClient(`reporte/CambiaEstatus/${details.id}/${result.data}`).catch(() =>
              notification.error({message: 'Error', description: 'Error al cambiar el estatus del servicio'}))

            notification.success({message: 'Exito', description: 'Servicio creado correctamente'})


            history.push(`/detalleAsistencia/${result.data}`)

          } else {
            notification.error({message: 'Error', description: 'Error al crear el servicio'})
          }
        }
      ).catch((e) => console.log(e))
    }

    useEffect(() => {
      void getCampaniasHdi()
    }, []);

    const serviceId = Form.useWatch('servicioID', form)
    const customForm =
      <>
        <Form
          scrollToFirstError={true}
          layout="vertical"
          form={form}
        >
          <div

            style={{display: 'flex', gap: '10px'}}
          >
            <Form.Item name={'tipoAsistencia'} label={'Tipo de asistencia por Campaña'}
                       rules={[{required: true, message: 'Seleccione un tipo de asistencia'}]}>
              <TreeSelect style={{width: '400px'}} treeDefaultExpandAll treeData={treeData.tree}
                          onChange={(value) => onChangeTypeService(value)}/>
            </Form.Item>

            <Form.Item name={'servicioID'} label={'Servicio'}
                       rules={[{required: true, message: 'Seleccione un tipo de servicio'}]}>
              <Select
                onChange={onSelectServiceId}
                style={{width: '250px'}}
                placeholder="Seleccione el tipo de servicio"
                options={optionsByAssistance}
              />
            </Form.Item>
          </div>
          {serviceId && <Row gutter={16}>
            {fields?.map((item, i) => (
              <Col span={6} key={i}>
                <Form.Item
                  name={item.label}
                  key={item.id}
                  label={item.label}
                  style={{textTransform: 'capitalize'}}
                  rules={[{required: item.esRequerido, message: `Por favor ingrese ${item.label}`}]}
                >
                  <Input placeholder={item.label}/>
                </Form.Item>
              </Col>
            ))}
          </Row>}
        </Form>
      </>

    return {customForm, submit}
  }
;
