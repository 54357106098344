import CardPage from "../../../layout/CardPage";
import { useHistory } from "react-router-dom";
import { Datos } from "./Datos";
import { Mapa } from "../../Mapa/Mapa";
import { useSelectCustom } from "./useCustomSelect";
import { Button } from "antd";
import { useState } from "react";

export const DetallesServicioHdi = () => {
  const [geoDatos, setGeoDatos] = useState({});
  const history = useHistory();
  const { customForm, submit } = useSelectCustom();
  const generateService = () => {
    submit(geoDatos);
  };
  return (
    <>
      <CardPage
        titulo="Crear servicio HDI"
        accionBoton={() => history.push("/hdiApi")}
        tituloBoton="Regresar"
      >
        <Datos />

       

        <div style={{margin: '20px 0', display: 'flex'}}>

          {customForm}

        </div>

        <Mapa setGeoDatos={setGeoDatos} />

        <Button onClick={generateService} type="primary">
          Generar Servicio
        </Button>
      </CardPage>
    </>
  );
};
